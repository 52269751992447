import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserHistory } from 'history';
import React, { lazy, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { GuardProvider } from 'react-router-guards';
import {
	GuardToRoute,
	Next,
	GuardFunctionRouteProps,
	GuardFunction,
} from 'react-router-guards/dist/types';
import { getSubdomain } from 'tldts';

import { fireDialog } from '../dialog/dialog.service';
import FeatureToggles from '../feature-toggles/feature-toggles.component';
import Navigation from '../navigation/navigation.component';
import { RootState } from '../state/root.reducer';
import {
	REDIRECTED_URLS,
	RedirectVenueHandler,
} from './redirect-venue-handler';

import Loading from 'components/loading/loading.component';
import BrandLandingPage from 'modules/brand/brand-landing/brand-landing.page';
import O2LandingPage from 'modules/brand/o2-landing-page/o2-landing-page.page';
import CheckoutStatusProvider from 'modules/checkout/checkout-status/checkout-status-provider.component';
import VenueLinkPage from 'modules/venue/venue-link/venue-link.page';

// TODO: remove this
export const INCORRECT_ZEDEL_ID = '06757306-3600-423d-ad9a-738989fff182' as const;
export const CORRECT_ZEDEL_ID = '0833c442-f44d-4242-8bb6-1968e033bfa8' as const;

const RefreshTokenGuard = lazy(
	() =>
		import('modules/auth/refresh-token-guard/refresh-token-guard.component'),
);
const LoadingPage = lazy(() => import('components/loading/loading.page'));

const AuthPage = lazy(() => import('modules/auth/auth.page'));
const BasketPage = lazy(() => import('modules/basket/basket.page'));
const CheckoutSuccessPage = lazy(
	() => import('modules/checkout/checkout-success/checkout-success.page'),
);
const MenuRouter = lazy(() => import('modules/menu/menu.router'));
const NotFoundPage = lazy(() => import('./not-found.page'));
const PayGoLandingPage = lazy(
	() => import('modules/pay-go/pay-go-landing/pay-go-landing.page'),
);
const PayGoOrdersPage = lazy(
	() => import('modules/pay-go/pay-go-orders/pay-go-orders.page'),
);
const PayGoOrderDetailsPage = lazy(
	() => import('modules/pay-go/pay-go-order-details/pay-go-order-details.page'),
);
const RegisterPage = lazy(() => import('modules/auth/register/register.page'));
const StripeProvider = lazy(
	() => import('modules/core/libraries/stripe/stripe-provider.component'),
);
const VenueLandingPage = lazy(
	() => import('modules/venue/venue-landing/venue-landing.page'),
);
const VenueListPage = lazy(
	() => import('modules/venue/venue-list/venue-list.page'),
);
const TabPickupLandingPage = lazy(
	() => import('modules/tab-pickup/tab-pickup-landing/tab-pickup-landing.page'),
);
const TabPickupOrdersPage = lazy(
	() => import('modules/tab-pickup/tab-pickup-orders/tab-pickup-orders.page'),
);
const TabPickupOrderDetailsPage = lazy(
	() =>
		import(
			'modules/tab-pickup/tab-pickup-order-details/tab-pickup-order-details.page'
		),
);

const SubscriptionLandingPage = lazy(
	() =>
		import(
			'modules/subscription/subscription-landing/subscription-landing.page'
		),
);

const ProfileRouter = lazy(() => import('modules/profile/profile.router'));

const SubscriptionSuccessPage = lazy(
	() =>
		import(
			'modules/subscription/subscription-success/subscription-success.page'
		),
);

const OrderHistoryRouter = lazy(
	() => import('modules/order-history/order-history.router'),
);

const GiftingRouter = lazy(() => import('modules/gifting/gifting.router'));

// Create and export browser history
export const history = createBrowserHistory();

/** Router component containing all application routes */
const AppRouter: React.FC = () => {
	// Get auth from state
	const { hasAuth } = useSelector((state: RootState) => state.auth);

	let subDomain = getSubdomain(window.location.href);
	if (subDomain && subDomain.indexOf('.')) {
		subDomain = subDomain.substring(0, subDomain.indexOf('.'));
	}

	/** Route guard for routes requiring login */
	const requireLogin: GuardFunction = async (
		to: GuardToRoute,
		from: GuardFunctionRouteProps | null,
		next: Next,
	) => {
		// If route requires auth
		if (to.meta.auth) {
			hasAuth ? next() : next.redirect('/');
		} else {
			next();
		}
	};

	const intl = useIntl();

	const getUserConfirmation = useCallback(
		async (message: string, callback: (val: boolean) => void) => {
			// Fire dialog
			const { value } = await fireDialog({
				title: intl.formatMessage({ id: 'appRouter.navigation.confirm.title' }),
				text:
					message ||
					intl.formatMessage({ id: 'appRouter.navigation.confirm.text' }),
				showCancelButton: true,
			});
			// callback result from dialog
			callback(value);
		},
		[intl],
	);

	return (
		<BrowserRouter getUserConfirmation={getUserConfirmation}>
			<CompatRouter>
				<RefreshTokenGuard>
					<FeatureToggles />
					<Navigation />
					<GuardProvider guards={[requireLogin]} error={NotFoundPage}>
						<Switch>
							<Route
								path={REDIRECTED_URLS}
								render={() => (
									<>
										<Loading />
										<RedirectVenueHandler />
									</>
								)}
							/>
							<Route
								exact
								path="/"
								render={() => (
									<>
										<Loading />
										{subDomain === 'gbk-o2' ? (
											<O2LandingPage />
										) : (
											<BrandLandingPage />
										)}
									</>
								)}
							/>
							<Route
								exact
								path="/venues"
								render={() => (
									<>
										<Loading />
										<VenueListPage />
									</>
								)}
							/>
							<Route
								exact
								path={['/venue/:venueId']}
								render={() => (
									<>
										<Loading />
										<VenueLandingPage />
									</>
								)}
							/>
							<Redirect
								from="/venue/:venueId/onpl/table/:tableNumber"
								to="/venue/:venueId/table-service/order-now-pay-later/table/:tableNumber"
							/>
							<Route
								exact
								path={['/venue/:venueId/pay-go']}
								render={() => (
									<>
										<Loading />
										<PayGoLandingPage />
									</>
								)}
							/>
							<Route
								exact
								path={['/venue/:venueId/pay-go/table/:tableNumber']}
								render={() => (
									<>
										<Loading />
										<PayGoOrdersPage />
									</>
								)}
							/>
							<Route
								exact
								path={['/venue/:venueId/pay-go/order/:posId']}
								render={() => (
									<StripeProvider>
										<Loading />
										<PayGoOrderDetailsPage />
									</StripeProvider>
								)}
							/>
							<Route
								exact
								path={['/venue/:venueId/tab-pickup']}
								render={() => (
									<>
										<Loading />
										<TabPickupLandingPage />
									</>
								)}
							/>
							<Route
								exact
								path={['/venue/:venueId/tab-pickup/table/:tableNumber']}
								render={() => (
									<>
										<Loading />
										<TabPickupOrdersPage />
									</>
								)}
							/>
							<Route
								exact
								path={['/venue/:venueId/tab-pickup/order/:posId']}
								render={() => (
									<>
										<Loading />
										<StripeProvider>
											<TabPickupOrderDetailsPage />
										</StripeProvider>
									</>
								)}
							/>
							<Route
								path={[
									'/venue/:venueId/table/:tableNumber',
									'/venue/:venueId/:serviceType/table/:tableNumber',
									'/venue/:venueId/:serviceType/:tableServiceFeature/table/:tableNumber',
									'/venue/:venueId/:serviceType/:tableServiceFeature/order/:posId',
									'/venue/:venueId/:serviceType/:tableServiceFeature',
									'/venue/:venueId/:serviceType',
								]}
								render={() => (
									<>
										<Loading />
										<VenueLinkPage />
									</>
								)}
							/>
							<Route
								exact
								path={[
									'/menu/:venueId',
									'/menu/:venueId/:menuId',
									'/menu/:venueId/order/:orderId',
									'/product/:venueId/:menuId/:productId',
								]}
								render={() => (
									<>
										<Loading />
										<MenuRouter />
									</>
								)}
							/>
							<Route
								exact
								path="/auth"
								render={() => (
									<>
										<Loading />
										<GoogleOAuthProvider
											clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
										>
											<AuthPage />
										</GoogleOAuthProvider>
									</>
								)}
							/>
							<Route
								exact
								path="/auth/register"
								render={() => (
									<>
										<Loading />
										<RegisterPage />
									</>
								)}
							/>
							<Route
								exact
								path={['/basket', '/basket/:clientId']}
								render={() => (
									<>
										<Loading />
										<StripeProvider>
											<BasketPage />
										</StripeProvider>
									</>
								)}
							/>
							<Route
								exact
								path={['/checkout/success', '/checkout/complete']}
								render={() => (
									<>
										<Loading />
										<CheckoutSuccessPage />
									</>
								)}
							/>
							<Route
								exact
								path={[
									'/subscription/:brandId/:subscriptionStatus',
									'/subscription/:brandId/:subscriptionStatus/complete',
								]}
								render={() => (
									<>
										<Loading />
										<SubscriptionSuccessPage />
									</>
								)}
							/>
							<Route
								exact
								path={['/subscription/:brandId']}
								render={() => (
									<>
										<Loading />
										<StripeProvider>
											<SubscriptionLandingPage />
										</StripeProvider>
									</>
								)}
							/>
							<Route
								exact
								path={['/profile/(terms|edit|allergens|privacy)?']}
								render={() => (
									<>
										<Loading />
										<ProfileRouter />
									</>
								)}
							/>
							<Route
								path={['/order-history']}
								render={() => (
									<>
										<Loading />
										<OrderHistoryRouter />
									</>
								)}
							/>
							<Route
								exact
								path={[
									'/gifting/(purchased|received|archive|create|payment)?',
									'/gifting/payment/success',
									'/gifting/details/:giftId/(terms-conditions|history)?',
								]}
								render={() => (
									<>
										<Loading />
										<GiftingRouter />
									</>
								)}
							/>
							<Route path={['/loading']} render={() => <LoadingPage />} />
							<Route
								render={() => (
									<>
										<Loading />
										<NotFoundPage />
									</>
								)}
							/>
						</Switch>
					</GuardProvider>
				</RefreshTokenGuard>
				<CheckoutStatusProvider />
			</CompatRouter>
		</BrowserRouter>
	);
};

export default AppRouter;
