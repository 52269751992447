import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { configureScope, init } from '@sentry/react';
import LogRocket from 'logrocket';

import { hostName } from 'helpers/environment-url.helper';

// Create new instance of OpenReplay tracker
export const tracker = new Tracker({
	projectKey: 'pM6J9o1bQST2eG8OYC7R', // TODO - from .env
	ingestPoint: 'https://openreplay.orderpay.com/ingest', // TODO - from .env
	revID: process.env.REACT_APP_VERSION,
	capturePerformance: true,
	network: {
		failuresOnly: false,
		sessionTokenHeader: true,
		capturePayload: true,
		captureInIframes: true,
		ignoreHeaders: [],
	},
	captureIFrames: true,
	verbose: true,
	obscureTextEmails: false,
	obscureInputEmails: false,
	defaultInputMode: 0,
	__DISABLE_SECURE_MODE: process.env.REACT_APP_ENVIRONMENT === 'local', // for local testing
});

/** Initialises logging tools */
const initialiseLogs = () => {
	// If we're not in local dev environment
	if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

	// eslint-disable-next-line no-console
	console.log(
		`%c  OrderPay Web Ordering (v${process.env.REACT_APP_VERSION}) - ${process.env.REACT_APP_ENVIRONMENT}`,
		[
			'background-color: #101010;',
			'background-image: url("https://cms.orderpay.com/apple-touch-icon.png")',
			'background-size: contain',
			'background-position: top left',
			'background-repeat: no-repeat',
			'padding: 20px 20px 20px 50px',
			'color: white',
			'font-size: 20px;',
		].join(';'),
	);

	try {
		// Initialise logrocket
		LogRocket.init('taaam2/orderpay-web-ordering', {
			release: process.env.REACT_APP_VERSION,
			rootHostname: hostName(),
			// Sanitise some network requests
			network: {
				requestSanitizer: (request) => {
					// Return the modified request
					return request;
				},
				responseSanitizer: (response) => {
					if (response && response.body) {
						try {
							JSON.parse(response.body);
						} catch (e) {
							return response;
						}

						// Parse body string
						const body = JSON.parse(response.body);

						// return response
						return {
							...response,
							body: JSON.stringify(body),
						};
					}
					return response;
				},
			},
			// Sanitize some browser data
			browser: {
				urlSanitizer: (url) => {
					let sanitizedUrl = url;
					// redact the value of the query parameter secret_key
					sanitizedUrl = sanitizedUrl.replace(
						/refreshToken=([^&]*)/,
						'refreshToken=**redacted**',
					);
					// make sure you return the sanitized URL string
					return sanitizedUrl;
				},
			},
		});

		// If we have sentry config
		if (process.env.REACT_APP_SENTRY_DSN) {
			// Initialise sentry
			init({
				dsn: process.env.REACT_APP_SENTRY_DSN,
				release: process.env.REACT_APP_VERSION,
				ignoreErrors: [
					// https://github.com/getsentry/sentry-javascript/issues/3440
					'Object Not Found Matching Id',
				],
			});

			// Get logrocket session URL
			LogRocket.getSessionURL((sessionURL) => {
				// Set sentry scope
				configureScope((scope) => {
					// Add logrocket session URL to sentry extra data
					scope.setExtra('sessionURL', sessionURL);
				});
			});
		}

		// Start OpenReplay tracker
		tracker.use(trackerAssist());
		tracker.start();

		// eslint-disable-next-line no-console
		console.info(
			`Logging initialised: (v${process.env.REACT_APP_VERSION}) - ${process.env.REACT_APP_ENVIRONMENT}`,
		);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('Logging: Failed to load logging plugins.');
	}
};

export default initialiseLogs;
