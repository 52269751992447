import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';

import {
	resetBasketState,
	setActivePaymentOption,
	setActiveServiceType,
	setActiveTableNumber,
	setPosOrderId,
} from 'modules/basket/basket.slice';
import { RootState } from 'modules/core/state/root.reducer';
import { resetOrderState } from 'modules/order/order.slice';
import { resetPayGoState } from 'modules/pay-go/pay-go.slice';

const useOnpl = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const navigate = useNavigate();
	const { activeBasket, venue } = useSelector(
		(state: RootState) => state.basket,
	);
	const { activeVenue } = useSelector((state: RootState) => state.venue);
	const { activeOrder } = useSelector((state: RootState) => state.checkout);

	const isOnpl = useMemo(
		() => {
			const onplEnabled = Boolean(
				activeVenue?.services?.tableService?.onplEnabled ||
					venue?.services?.tableService?.onplEnabled ||
					activeOrder?.venue?.services?.tableService?.onplEnabled,
			);

			return onplEnabled;
		},
		[activeBasket, activeVenue],
	);

	const navigateBack = async (
		venueId: string,
		tableNumber?: string,
		posId?: string,
		keepBasket?: boolean,
		keepPayGoState?: boolean,
		resetOrder?: boolean,
	) => {
		await dispatch(resetOrderState());

		if (!keepBasket) {
			await dispatch(resetBasketState());
		}

		if (!keepPayGoState) {
			await dispatch(resetPayGoState());
		}

		if (posId) {
			await dispatch(setActiveServiceType('tableService'));
			tableNumber && (await dispatch(setActiveTableNumber(tableNumber)));
			await dispatch(setActivePaymentOption('OrderAndPay'));
			if (resetOrder) {
				history.push(`/menu/${venueId}`);
			} else {
				await dispatch(setPosOrderId(posId));
				history.push(`/menu/${venueId}/order/${posId}`);
			}
		} else {
			await dispatch(resetPayGoState());
			await dispatch(resetOrderState());

			if (!keepBasket) {
				await dispatch(resetBasketState());
			}
			navigate(`/menu/${venueId}`);
		}
	};

	return { isOnpl, navigateBack };
};

export default useOnpl;
