import { configureStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import axiosMiddleware from 'redux-axios-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import ecommerceStateTrackingMiddleware from '../analytics/middlewear/ecommerce-state-tracking.middlewear';
import httpClient, { httpMiddlewareConfig } from '../http/http.config';
import { CurrentCategoryTransformer } from './helpers/current-category-id-transformer.helper';
import { LoadingStateTransformer } from './helpers/loading-state-transformer.helper';
import { PairingVisibleTransformer } from './helpers/pairing-visible-transformer.helper';
import ResetLoadingFlags from './helpers/reset-loading-flags';
import openReplayMiddleware from './openreplay.middleware';
import refreshMiddleware from './refresh.middleware';
import rootReducer from './root.reducer';

// Configure redux-persist
const persistConfig = {
	key: 'wow-root',
	storage,
	transforms: [
		LoadingStateTransformer,
		PairingVisibleTransformer,
		ResetLoadingFlags,
		CurrentCategoryTransformer,
	],
	blacklist: ['orderHistory', 'splitBill', 'toast'],
};

// Persist root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure redux store using redux toolkit
const store = configureStore({
	reducer: persistedReducer,
	middleware: [
		thunk,
		refreshMiddleware,
		axiosMiddleware(httpClient, httpMiddlewareConfig),
		ecommerceStateTrackingMiddleware,
		LogRocket.reduxMiddleware({
			stateSanitizer(state) {
				return {
					...state,
					auth: undefined,
				};
			},
			actionSanitizer(action) {
				// Remove auth and persist requests as they have sensitive information within
				if (action.type.includes('auth/') || action.type.includes('persist/')) {
					return null;
				}
				return action;
			},
		}),
		openReplayMiddleware,
	],
});

// Persist store
export const persistor = persistStore(store);

// If we're in development mode
if (process.env.NODE_ENV === 'development' && module.hot) {
	// Hot reload the root reducer
	module.hot.accept('./root.reducer', () => {
		// eslint-disable-next-line global-require
		const newRootReducer = require('./root.reducer').default;
		store.replaceReducer(newRootReducer);
	});
}

// Create a type for dispatches
export type AppDispatch = typeof store.dispatch;

export default store;
